import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form'
import useUserCurrency from '../../../hooks/useUserCurrency'
import { formatMoney } from '../../../util/number'
import HelpText, { HelpTextProps } from '../../HelpText'
import { CustomMessageType } from '../FormBuilder'
import { FormElementErrorMessage } from './Input'

export type BankAccountNumberInputProps = {
  label?: string
  property?: string
  placeholder?: string
  required?: boolean
  showOptionalIfNotRequired?: boolean
  register?: UseFormRegister<any>
  setValue?: UseFormSetValue<any>
  getValues?: UseFormGetValues<any>
  trigger?: UseFormTrigger<any>
  validators?: any
  type?: string
  inputType?: 'text' | 'email' | 'search' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal'
  accept?: string
  errors?: FieldErrors
  minValue?: string | number
  maxValue?: string | number
  autocomplete?: string
  readonly?: boolean
  disabled?: boolean
  minLength?: number
  maxLength?: number
  pattern?: RegExp
  value?: any
  prefix?: string
  postfix?: string
  customMessage?: string
  customMessageType?: CustomMessageType
  customPatternErrorMessage?: string
  onChange?: (args?: any) => void
  onBlur?: (args?: any) => void
  onKeyDownEvent?: (args?: any) => void
  selectOnClick?: boolean
  additionalActionText?: string
  additionalActionFn?: (...args) => any
  helpText?: HelpTextProps
  textAlign?: 'text-left' | 'text-center' | 'text-right'
  backgroundColor?: string
  textColor?: string
  showLabel?: boolean
  fixedCountryCallingCode?: any
}

export const BankAccountNumberInput = ({
  label = '',
  required = false,
  showOptionalIfNotRequired = true,
  property = undefined,
  placeholder = undefined,
  register = (...args) => null,
  onChange = (...args) => null,
  onBlur = (...args) => null,
  onKeyDownEvent = (...args) => null,
  validators = {} as any,
  type = undefined,
  inputType = 'text',
  accept = undefined,
  errors = undefined,
  autocomplete = undefined,
  readonly = false,
  disabled = false,
  minValue = undefined,
  maxValue = undefined,
  minLength = undefined,
  maxLength = undefined,
  pattern = undefined,
  value = undefined,
  prefix = undefined,
  postfix = undefined,
  customMessage = undefined,
  customMessageType = CustomMessageType.Error,
  customPatternErrorMessage = undefined,
  selectOnClick = false,
  additionalActionText = '',
  additionalActionFn = null,
  helpText = null,
  textAlign = 'text-left',
  backgroundColor = null,
  textColor = null,
  showLabel = true,
  getValues,
  setValue,
}: BankAccountNumberInputProps) => {
  const currency = useUserCurrency()

  const securityCodeProperty = 'accountSecurityCode'

  const { t } = useTranslation('account')

  const initValue = getValues ? getValues(property) : value
  const [currentValue, setCurrentValue] = useState(initValue)

  const initSecCodeValue = getValues ? getValues(securityCodeProperty) : ''
  const [currentSecCodeValue, setCurrentSecCodeValue] = useState(initSecCodeValue)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const _onChange = (ev) => {
    const val = ev?.target?.value ?? ''
    setCurrentValue(val)
    onChange(ev)
    setValue(property, val, {
      shouldValidate: true,
      shouldTouch: true,
    })
  }

  const _onSecCodeChange = (ev) => {
    const val = ev?.target?.value ?? ''
    setCurrentSecCodeValue(val)
    onChange(ev)
    setValue(securityCodeProperty, val, {
      shouldValidate: true,
      shouldTouch: true,
    })
  }

  const _onKeyDownEvent = (event: any) => {
    // console.log(`onKeyDown`)
    onKeyDownEvent(event)
  }

  const fieldErrors = (errors[property] ?? errors[securityCodeProperty]) as any

  return (
    <div className="form-input flex flex-grow flex-col">
      <div className="form-input flex flex-grow">
        <div className="form-input flex flex-grow flex-col w-4/6">
          {label && showLabel && (
            <FormElementLabel
              label={label}
              property={property}
              required={required}
              showOptionalIfNotRequired={showOptionalIfNotRequired}
              readonly={readonly}
              helpText={helpText}
            />
          )}
          <div
            className={`form-input-inner flex space-x-2 mr-4 items-center transition ${
              backgroundColor ? backgroundColor : 'bg-form hover:bg-formHover'
            } ${
              textColor ? textColor : 'text-formContrast hover:text-formHoverContrast'
            } border-[.5px] border-formBorder hover:border-formHoverBorder rounded-md h-[42px] px-4 py-[10px] ${
              !disabled && !readonly ? 'focus:border-primary' : ''
            } ${fieldErrors != null ? 'border-red-500 border-opacity-100' : ''} ${readonly || disabled ? ' opacity-60' : ''}`}
          >
            {prefix && (
              <div className="form-input-prefix text-sm font-semibold opacity-50">
                <span>{prefix}</span>
              </div>
            )}

            <input
              className={`form-input-input flex w-full h-full break-all text-sm bg-transparent focus:outline-none appearance-none ${textAlign}`}
              name={property}
              id={property}
              placeholder={placeholder}
              onChange={_onChange}
              onBlur={onBlur}
              type="text"
              inputMode={inputType}
              accept={accept}
              min={minValue}
              max={maxValue}
              step="any"
              autoComplete={autocomplete}
              readOnly={readonly}
              disabled={disabled}
              {...register(property, {
                required: required,
                min: minValue,
                max: maxValue,
                minLength: minLength,
                maxLength: maxLength,
                pattern: pattern,
                validate: validators,
              })}
              onKeyDown={_onKeyDownEvent}
            />
          </div>
        </div>
        <div className="form-input flex flex-grow flex-col w-2/6">
          {showLabel && (
            <FormElementLabel
              label={t('Account.accountSecurityCode')}
              property={securityCodeProperty}
              required={required}
              showOptionalIfNotRequired={showOptionalIfNotRequired}
              readonly={readonly}
              helpText={helpText}
            />
          )}
          <div
            className={`form-input-inner flex space-x-2 items-center transition ${
              backgroundColor ? backgroundColor : 'bg-form hover:bg-formHover'
            } ${
              textColor ? textColor : 'text-formContrast hover:text-formHoverContrast'
            } border-[.5px] border-formBorder hover:border-formHoverBorder rounded-md h-[42px] px-4 py-[10px] ${
              !disabled && !readonly ? 'focus:border-primary' : ''
            } ${fieldErrors != null ? 'border-red-500 border-opacity-100' : ''} ${readonly || disabled ? ' opacity-60' : ''}`}
          >
            <input
              className={`form-input-input flex w-full h-full break-all text-sm bg-transparent focus:outline-none appearance-none ${textAlign}`}
              name={securityCodeProperty}
              id={securityCodeProperty}
              onChange={_onSecCodeChange}
              onBlur={onBlur}
              type="text"
              inputMode={inputType}
              accept={accept}
              min={minValue}
              max={maxValue}
              step="any"
              autoComplete={autocomplete}
              readOnly={readonly}
              disabled={disabled}
              {...register(securityCodeProperty, {
                required: required,
                validate: validators,
              })}
              onKeyDown={_onKeyDownEvent}
            />

            {postfix && (
              <div className="form-input-postfix text-sm font-semibold text-gray-500">
                {postfix != null && <span>{postfix}</span>}
              </div>
            )}
          </div>
        </div>
      </div>

      {additionalActionText && (
        <div
          className="form-input-additional-action flex justify-end w-full text-primary text-xs font-semibold mt-2 cursor-pointer hover:underline"
          onClick={additionalActionFn}
        >
          {additionalActionText}
        </div>
      )}

      <FormElementErrorMessage
        customMessage={customMessage}
        customMessageType={customMessageType}
        customPatternErrorMessage={customPatternErrorMessage}
        errors={fieldErrors}
        minLength={minLength}
        maxLength={maxLength}
        minValue={formatMoney(Number(minValue), currency)}
        maxValue={formatMoney(Number(maxValue), currency)}
      />
    </div>
  )
}

export type FormElementLabelProps = {
  label: string
  property?: string
  required?: boolean
  showOptionalIfNotRequired?: boolean
  readonly?: boolean
  helpText?: HelpTextProps
  className?: string
}
export const FormElementLabel = ({
  label,
  property,
  required,
  showOptionalIfNotRequired,
  readonly,
  helpText,
  className = '',
}: FormElementLabelProps) => {
  const { t } = useTranslation()

  return (
    <label htmlFor={property} className={'form-element-label flex items-center space-x-1 text-xs mb-1 ' + className}>
      <span className="opacity-60">
        {label}
        {!required && !readonly && showOptionalIfNotRequired && ' (' + t('common:Common.optional') + ')'}
      </span>
      {helpText && <HelpText {...helpText} />}
    </label>
  )
}
