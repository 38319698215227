import React from 'react'
import useUserCurrency from '../../../hooks/useUserCurrency'
import { formatDateWithTime } from '../../../util/date'
import { formatMoney } from '../../../util/number'
import { FormElementErrorMessage, TextInput, TextInputProps } from './Input'
import useTranslation from 'next-translate/useTranslation'

type SelflimitInputProps = TextInputProps & {
  usedUp?: number
  currentLimit?: number
  nextPossibleIncreasement?: string
}

const SelflimitInput = (props: SelflimitInputProps) => {
  return (
    <div className="self-limit-input flex flex-col space-y-1 mb-4">
      <TextInput {...props} customMessage={null} />
      {props.customMessage != null && <FormElementErrorMessage {...props} />}
      {(props.currentLimit > 0 ||
        (props.nextPossibleIncreasement != null && props.nextPossibleIncreasement != '0001-01-01T00:00:00')) && (
        <SelflimitProgressBar {...props} />
      )}
    </div>
  )
}

const SelflimitProgressBar = ({ usedUp = 0, currentLimit = 0, nextPossibleIncreasement = '' }) => {
  const { t } = useTranslation('change-selflimits')
  const currency = useUserCurrency()

  // display at least 10% if something is already used up
  let widthPercentage = Math.min((usedUp / currentLimit) * 100, 100)
  if (widthPercentage > 0 && widthPercentage <= 10) widthPercentage = 10

  return (
    <div className="self-limit-progress-bar flex flex-col space-y-2 w-full mt-2">
      {currentLimit && currentLimit > 0 && (
        <div className="self-limit-progress-bar-bg flex items-center relative w-full h-6 bg-form rounded-md p-1 border-[1px] border-formBorder">
          <div
            className={'self-limit-progress-bar-progress flex h-full rounded-sm bg-secondary animate-shine'}
            style={{ width: widthPercentage + '%' }}
          ></div>
          <span className="self-limit-progress-bar-label absolute right-2 flex text-xs font-medium leading-[0px] text-formContrast opacity-70">
            {formatMoney(Math.max(usedUp, 0), currency)} / {formatMoney(currentLimit, currency)}
          </span>
        </div>
      )}

      {nextPossibleIncreasement && nextPossibleIncreasement != '0001-01-01T00:00:00' && (
        <span className="self-limit-progress-bar-next-inc flex text-xs justify-center opacity-70">
          {t('ChangeSelflimitsPage.nextPossibleIncreasement')}: {formatDateWithTime(nextPossibleIncreasement as any)}
        </span>
      )}
    </div>
  )
}

export default SelflimitInput
