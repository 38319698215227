import { KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react'
import React from 'react'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SelectOption } from '../../../util/select-option'
import { Control, Controller, ControllerRenderProps, FieldErrors } from 'react-hook-form'
import { HelpTextProps } from '../../HelpText'
import { FormElementErrorMessage, FormElementLabel } from './Input'
import Fuse from 'fuse.js'
import { throttleTime, distinctUntilChanged, asyncScheduler, Subject, Subscription } from 'rxjs'

const Option = ({ name = '', value = '', selected = false, onSelect = () => {} }) => {
  const onClick = (ev: MouseEvent<HTMLDivElement>) => {
    ev.preventDefault()
    ev.stopPropagation()
    onSelect()
  }

  return (
    <div className="hover:bg-form py-2 text-xs md:py-1 md:text-base px-2" data-value={value} onClick={onClick}>
      {name}
    </div>
  )
}

const areSame = (a: string, b: string) => {
  const res = a.localeCompare(b, undefined, {
    sensitivity: 'base',
  })
  return res == 0
}

export type SearchableSelectFieldProps = {
  selectOptions: SelectOption[]
  placeHolder?: string | undefined
  value: any
  onSelect?: (selected: any | undefined) => void
}

export type DropDownStyle = { top: string; left: string; width: string; height: string }

export type SearchableSelectFieldState = {
  placeHolderHidden: boolean
  labelHidden: boolean
  dropDownHidden: boolean
  dropDownStyle: DropDownStyle
  selectedOption: SelectOption | undefined
  label: string | undefined
  value: string | undefined
  selectOptions: SelectOption[]
}

class SearchableSelectField extends React.Component<SearchableSelectFieldProps, SearchableSelectFieldState> {
  private dropDownEl = React.createRef<HTMLDivElement>()
  private inputEl = React.createRef<HTMLInputElement>()
  private iconEl = React.createRef<SVGSVGElement>()
  private searchIndex: Fuse<SelectOption>
  private searchQuery = new Subject<string>()
  private searchSub: Subscription | undefined

  componentWillMount() {}

  onClose = () => {
    // console.log(`onClose`)
    this.setState({
      ...this.state,
      dropDownHidden: true,
    })
  }

  componentDidMount() {
    // console.log(`componentWillMount`)
    const { value: currentValue, selectOptions, placeHolder } = this.props

    this.searchIndex = new Fuse<SelectOption>(selectOptions, {
      shouldSort: true,
      isCaseSensitive: false,
      threshold: 0.2,
      includeScore: false,
      keys: ['name'],
    })

    const currentOption = currentValue ? selectOptions.find((b) => b.value === currentValue) : undefined
    console.log(currentValue, currentOption)

    this.setState({
      placeHolderHidden: !placeHolder,
      labelHidden: currentOption === undefined,
      dropDownHidden: true,
      label: currentOption?.name,
      value: currentValue,
      selectOptions: selectOptions,
      dropDownStyle: {
        left: '0',
        top: '0',
        width: '0',
        height: '0',
      },
    })

    document.body.addEventListener('click', this.onClose)

    const search = (query: string) => {
      const searchResult = this.searchIndex.search(query)
      return searchResult.map((r) => r.item)
    }

    this.searchSub = this.searchQuery
      .pipe(distinctUntilChanged(areSame), throttleTime(750, asyncScheduler, { leading: true, trailing: true }))
      .subscribe((val) => {
        // console.log(`searchQuery`, val)
        const newOptions = val != '' ? search(val) : selectOptions

        this.setState({
          ...this.state,
          selectOptions: newOptions,
        })
      })

    return function cleanup() {}
  }

  componentWillUnmount(): void {
    document.body.removeEventListener('click', this.onClose)
    if (this.searchSub) this.searchSub.unsubscribe()
  }

  private onOpen(ev: MouseEvent<HTMLSpanElement>) {
    ev.stopPropagation()
    ev.preventDefault()

    if (this.state.dropDownHidden == false) return

    const dropDownStyle = this.calculateDropDownStyle()
    const newState: SearchableSelectFieldState = {
      ...this.state,
      dropDownHidden: false,
      dropDownStyle,
    }
    // console.log(`onOpen`, newState)
    this.setState(newState)
  }

  private onInput(ev: React.FormEvent<HTMLInputElement>) {
    ev.stopPropagation()
    ev.preventDefault()

    const hasValue = ev.currentTarget.value.length != 0
    const placeHolderHidden = hasValue || !this.props.placeHolder

    this.setState({
      ...this.state,
      labelHidden: hasValue,
      placeHolderHidden,
    })

    // console.log(`onInput`, ev.currentTarget.value, this.searchQuery)

    this.searchQuery.next(ev.currentTarget.value)
  }

  private onToggle(ev: MouseEvent<SVGSVGElement>) {
    ev.stopPropagation()
    ev.preventDefault()
    const dropDownStyle = this.calculateDropDownStyle()
    const newState: SearchableSelectFieldState = {
      ...this.state,
      dropDownHidden: !this.state.dropDownHidden,
      dropDownStyle,
    }
    // console.log(`onToggle`, newState)
    this.setState(newState)
  }

  private onSelectOption(value: any) {
    const { selectOptions, onSelect } = this.props
    const newOption = value ? selectOptions.find((b) => b.value === value) : undefined
    const label = newOption?.name ?? ''

    this.inputEl.current.value = ''
    this.searchQuery.next('')

    this.setState({
      ...this.state,
      value,
      label,
      placeHolderHidden: !!newOption,
      dropDownHidden: true,
      labelHidden: !newOption,
    })

    if (onSelect) onSelect(value)
  }

  private calculateDropDownStyle(): DropDownStyle {
    const clientHeight = window.innerHeight
    const inputRect = this.inputEl.current.getBoundingClientRect()
    const iconRect = this.iconEl.current.getBoundingClientRect()
    const height = Math.floor((clientHeight - inputRect.bottom) * 0.8)

    const newStyle = {
      top: `${inputRect.height + 14}px`,
      left: `4px`,
      width: `${inputRect.width + iconRect.width + 30}px`,
      height: `${height}px`,
    }
    // console.log(newStyle)
    return newStyle
  }

  // render will know everything!
  render() {
    if (!this.state) return <></>

    const { placeHolder } = this.props
    const {
      placeHolderHidden,
      label: currentLabel,
      dropDownHidden,
      value: currentValue,
      labelHidden,
      selectOptions: options,
      dropDownStyle,
    } = this.state

    return (
      <div className="searchable-select w-full h-full grow">
        <span
          className="label flex relative w-full h-full space-x-2 items-stretch content-stretch transition bg-transparent focus:outline-none appearance-none cursor-pointer"
          onClick={(ev) => this.onOpen(ev)}
        >
          <span className="grow" hidden={placeHolderHidden}>
            {placeHolder ?? ''}
          </span>
          <span className="grow relative">
            <input
              onInput={(ev) => this.onInput(ev)}
              ref={this.inputEl}
              className="bg-transparent focus:outline-none overflow-x-visible appearance-none w-full h-full"
            ></input>
            <span
              hidden={labelHidden}
              className="absolute w-full h-full pointer-events-none top-0 left-0 overflow-hidden text-ellipsis"
            >
              {currentLabel}
            </span>
          </span>
          <FontAwesomeIcon
            icon={faChevronDown}
            className="opacity-60"
            onClick={(ev) => this.onToggle(ev)}
            ref={this.iconEl}
          />
        </span>

        <div
          className="dropdown z-10 absolute overflow-x-hidden overflow-y-auto bg-contentMain text-formContrast border-[.5px] border-formBorder hover:border-formHoverBorder rounded-md px-2 py-[10px]"
          hidden={dropDownHidden}
          style={dropDownStyle}
          ref={this.dropDownEl}
        >
          {options.map((el) => (
            <Option
              key={el.value}
              name={el.name}
              value={el.value}
              selected={currentValue == el.value}
              onSelect={() => this.onSelectOption(el.value)}
            ></Option>
          ))}
        </div>
      </div>
    )
  }
}

type SearchableSelectControlledProps = {
  field: ControllerRenderProps<any>
  selectOptions: SelectOption[]
  placeHolder?: string
}

const SearchableSelectControlled = ({ field, selectOptions, placeHolder }: SearchableSelectControlledProps) => {
  const onSelect = (val: any) => {
    field.onChange(val)
  }
  return (
    <SearchableSelectField
      onSelect={onSelect}
      selectOptions={selectOptions}
      placeHolder={placeHolder}
      value={field.value}
    ></SearchableSelectField>
  )
}

export type SearchableSelectProps = {
  label?: string
  required?: boolean
  showOptionalIfNotRequired: boolean
  property?: string
  selectOptions?: SelectOption[]
  errors?: FieldErrors
  control: Control<any, any>
  backgroundColor?: string
  textColor?: string
  placeHolder?: string
  readonly?: boolean
  disabled?: boolean
  value?: any
  onChange?: (...args: any) => void
  additionalActionText?: string
  additionalActionFn?: (...args) => any
  helpText?: HelpTextProps
}

const SearchableSelect = ({
  label = '',
  required = false,
  showOptionalIfNotRequired = true,
  property = undefined,
  selectOptions = [],
  errors = undefined,
  control,
  additionalActionText = '',
  additionalActionFn = null,
  helpText = null,
  backgroundColor = null,
  textColor = null,
  readonly = false,
  disabled = false,
  placeHolder = undefined,
}: SearchableSelectProps) => {
  return (
    <div className="form-select flex flex-col mb-4">
      <FormElementLabel
        label={label}
        property={property}
        required={required}
        showOptionalIfNotRequired={showOptionalIfNotRequired}
        helpText={helpText}
      />

      <div
        className={`form-input-inner flex space-x-2 items-center transition relative
         ${backgroundColor ? backgroundColor : 'bg-form hover:bg-formHover'} ${
           textColor ? textColor : 'text-formContrast hover:text-formHoverContrast'
         } border-[.5px] border-formBorder hover:border-formHoverBorder rounded-md h-[42px] px-4 py-[10px] ${
           !disabled && !readonly ? 'focus:border-primary' : ''
         } ${errors != null ? 'border-red-500 border-opacity-100' : ''} ${readonly || disabled ? ' opacity-60' : ''}`}
      >
        <Controller
          control={control}
          name={property}
          render={({ field }) => (
            <SearchableSelectControlled field={field} selectOptions={selectOptions} placeHolder={placeHolder} />
          )}
        ></Controller>
      </div>

      {additionalActionText && (
        <div
          className="form-select-additional-action flex justify-end w-full text-primary text-xs font-semibold mt-2 cursor-pointer"
          onClick={additionalActionFn}
        >
          {additionalActionText}
        </div>
      )}

      <FormElementErrorMessage errors={errors} />
    </div>
  )
}

export default SearchableSelect
