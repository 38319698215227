import { FormElementErrorMessage, FormElementLabel } from './Input'
import { CustomMessageType } from '../FormBuilder'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'

export const CpfInput = ({
  label = '',
  property = undefined,
  required = false,
  showOptionalIfNotRequired = true,
  readonly = false,
  register = (...args) => null,
  setValue = (...args) => null,
  getValues = (...args) => null,
  validators = {} as any,
  minLength = undefined,
  maxLength = undefined,
  errors = undefined,
  disabled = false,
  customMessage = undefined,
  customMessageType = CustomMessageType.Error,
  additionalActionText = '',
  additionalActionFn = null,
  helpText = null,
  cpfValidationState = undefined,
}) => {
  const [currentCpfValue, setCurrentCpfValue] = useState('')
  const [currentCpfRaw, setCurrentCpfRaw] = useState('')

  useEffect(() => {
    const formValue = getValues != null ? getValues(property) : null
    if (formValue != null && formValue != '') {
      setCurrentCpfRaw(formValue)
      setCurrentCpfValue(applyCpfMask(formValue))
    }
  }, [])

  const applyCpfMask = (value: any) => {
    value = value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')

    return value
  }

  const removeCpfMask = (value: string) => {
    value = value.replace(/\D/g, '')
    return value.substring(0, 11)
  }

  const onCpfChange = (ev) => {
    const { value } = ev.target

    setCurrentCpfValue(applyCpfMask(value))
    setCurrentCpfRaw(removeCpfMask(value))

    // set value manually, otherwise not recognized by react-hook-forms
    setValue(property, removeCpfMask(value), {
      shouldValidate: true,
      shouldTouch: true,
    })
  }

  return (
    <div className="cpf-input flex flex-col mb-4">
      <FormElementLabel
        label={label}
        property={property}
        required={required}
        readonly={readonly}
        showOptionalIfNotRequired={showOptionalIfNotRequired}
        helpText={helpText}
      />

      <div
        className={`cpf-input-inner relative flex space-x-2 items-center transition 
          bg-form hover:bg-formHover text-formContrast hover:text-formHoverContrast
          border-[.5px] border-formBorder hover:border-formHoverBorder rounded-md h-[42px] px-4 py-[10px] ${
            !disabled && !readonly ? 'focus:border-primary' : ''
          } ${errors != null ? 'border-red-500 border-opacity-100' : ''} ${readonly || disabled ? ' opacity-60' : ''}`}
      >
        <input
          className={`cpf-input-input flex w-full h-full break-all text-sm bg-transparent focus:outline-none appearance-none`}
          placeholder={'000.000.000-00'}
          onChange={onCpfChange}
          readOnly={readonly}
          disabled={disabled}
          value={currentCpfValue}
          type="text"
          inputMode="text"
        />

        {cpfValidationState != undefined && (
          <div className={'absolute right-0 h-full aspect-square flex items-center justify-center'}>
            {cpfValidationState === 'valid' && <FontAwesomeIcon icon={faCheckCircle} className={'text-green-500'} />}
            {cpfValidationState === 'invalid' && (
              <FontAwesomeIcon icon={faExclamationCircle} className={'text-red-500'} />
            )}
            {cpfValidationState === 'checking' && (
              <FontAwesomeIcon icon={faSpinnerThird} className={'text-blue-500 animate-spin'} />
            )}
          </div>
        )}

        <input
          type="hidden"
          id={property}
          name={property}
          value={currentCpfRaw}
          {...register(property, {
            required: required,
            validate: validators,
          })}
        />
      </div>

      {additionalActionText && (
        <div
          className="cpf-input-additional-action flex justify-end w-full text-primary text-xs font-semibold mt-2 cursor-pointer"
          onClick={additionalActionFn}
        >
          {additionalActionText}
        </div>
      )}

      <FormElementErrorMessage
        customMessage={customMessage}
        customMessageType={customMessageType}
        errors={errors}
        minLength={minLength}
        maxLength={maxLength}
      />
    </div>
  )
}
